import * as Sentry from '@sentry/browser';
import { getToken } from '@/services/firebase/auth';
import config from '@lumiere/shared/config';
import logger from '@lumiere/shared/services/logger';
import { transformedFetch } from '../utils/api';
const apiLogger = logger.extend('viewerAPI');
const fetch = transformedFetch({
    retries: 5,
    retryDelay(attempt) {
        const delay = (attempt + 1) * 1000; // 1000, 2000, 3000, 4000, 5000
        apiLogger.debug(`Will retry failed fetch request after  ${delay}, attempt: ${attempt}`);
        return delay;
    },
});
const apiHost = `${config.adminAppURL}/viewer-api`;
const fetchJSON = async (url, req) => fetch(url, {
    mode: 'cors',
    credentials: 'include',
    ...req,
}).then((fetchResponse) => {
    if (fetchResponse.ok) {
        return fetchResponse.json();
    }
    Sentry.captureMessage(fetchResponse.statusText, { tags: { url } });
    apiLogger.error(`💥 API call failed: ${fetchResponse.statusText}`);
    throw new Error(fetchResponse.statusText);
}, (reason) => {
    apiLogger.error('💥 Fetch request failed', reason);
    Sentry.captureException(reason, { tags: { url } });
    throw new Error(reason);
});
const processFunctionCall = (path) => async (...args) => {
    const name = path.join('.');
    const timeEnd = apiLogger.time(name, { args });
    return logger.timePromise('getToken', getToken()).then(async (token) => {
        return fetchJSON(`${apiHost}/${path.join('/')}`, {
            body: JSON.stringify({
                args,
                authorization: { token },
                referrer: location.href,
            }),
            method: 'POST',
            headers: {
                /** Authorization: token
                    Fetch API draws distinction between 'simple' and 'complex' requests.
                    The difference is that for complex requests a pre-flight OPTIONS request is required
                    We want to avoid the pre-flight request as it delays the request we need to make
                    To make the request simple we are limited to a set number of headers and data-types we can send
                    Authorization header is not one of the allowed headers, thus the token was moved into request body
                    More info here: https://javascript.info/fetch-crossorigin
                 */
                'Content-Type': 'text/plain',
            },
        }).then(({ ok, error }) => {
            timeEnd({ response: ok || error });
            if (error) {
                apiLogger.error(`💩 Error in response`, error);
                Sentry.captureMessage(error, { tags: { name } });
                throw new Error(error);
            }
            return ok;
        });
    });
};
const proxy = (path = []) => new Proxy(() => { }, {
    get: (_target, prop) => proxy([...path, prop]),
    apply: (_, _this, args) => processFunctionCall(path)(...args),
});
const viewerAPI = proxy();
export default viewerAPI;
