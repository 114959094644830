import Vue from 'vue';
import ACTION from './store/ACTION';
import MUTATION from './store/MUTATION';
import CaptureScreenOrientation from '@/components/commons/CaptureScreenOrientation.vue';
import HandleIframe from '@/components/commons/HandleIframe.vue';
export default Vue.extend({
    created() {
        this.$store.commit(MUTATION.SET_QUERY_PARAMS, this.$route.query);
    },
    mounted() {
        void this.$store.dispatch(ACTION.SUBSCRIBE_TO_AUTH_STATE_CHANGE);
    },
    components: {
        HandleIframe,
        CaptureScreenOrientation,
    },
});
