import config from '@lumiere/shared/config';
import urlJoin from 'url-join';
import { knuthShuffle } from 'knuth-shuffle';
import { getVideoPoster } from '@lumiere/shared/utils/getVideoThumbnail';
const VIDEO_PLACEHOLDER_IMAGE_URL = urlJoin(config.adminAppURL, 'img', 'video_image_placeholder.jpg');
const getters = {
    getHeadParams(state, getters) {
        return () => {
            if (state.video) {
                const { video } = state.video;
                const { title, description } = video;
                const image = getVideoPoster(video) ?? VIDEO_PLACEHOLDER_IMAGE_URL;
                const url = getters.videoURL(video.id);
                return {
                    title,
                    description,
                    image,
                    url,
                };
            }
            else if (state.channel) {
                const { channel } = state.channel;
                const { title, description, image } = channel;
                const url = getters.channelURL(channel.id);
                return {
                    title,
                    description: description || '',
                    url,
                    image: image ?? VIDEO_PLACEHOLDER_IMAGE_URL,
                };
            }
            else if (state.playlist) {
                return null;
            }
            return null;
        };
    },
    getUser(state) {
        return state.user;
    },
    isAnonymousUser(state) {
        return state.user ? state.user.isAnonymous : undefined;
    },
    iFrameEmbedData(state) {
        return state.iFrameEmbed
            ? {
                ...state.iFrameEmbed.params,
                parentURL: state.iFrameEmbed.parentURL,
            }
            : null;
    },
    channelVideos(state) {
        if (state.channel) {
            return state.channel.channel.options.shuffleVideos
                ? knuthShuffle(state.channel.channelVideos)
                : state.channel.channelVideos;
        }
        else
            return [];
    },
    videoURL() {
        return (videoId) => urlJoin(config.viewerAppURL, 'v', videoId);
    },
    channelURL() {
        return (channelId) => urlJoin(config.viewerAppURL, 'c', channelId);
    },
    channelTags(state) {
        const tags = state.channel?.channel.channelTags || {};
        return (state.channel?.channel.options.shuffleGroups
            ? knuthShuffle(Object.keys(tags))
            : Object.keys(tags).sort((a, b) => tags[a].nonce - tags[b].nonce)).map((id) => ({ ...tags[id], id }));
    },
};
export default getters;
