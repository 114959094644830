import { ChannelTag } from '@lumiere/db/types'

export interface VideosFiltersType {
  tag?: string
  search?: string
}

export type ChannelTagWithId = ChannelTag & { id: string }

export enum LUMIERE_EMBED_EVENTS {
  FETCH_VIDEO = 'FETCH_VIDEO',
  FETCH_CHANNEL = 'FETCH_CHANNEL',
  CREATE_VIEW_RECORD = 'CREATE_VIEW_RECORD',
  POST_DATA = 'POST_DATA',
  VIEW_SESSION_ENDED = 'VIEW_SESSION_ENDED',
  TRACK_VIDEO_ENGAGEMENT = 'TRACK_VIDEO_ENGAGEMENT',
  CHANNEL_WINDOW_DIMENSION = 'CHANNEL_WINDOW_DIMENSION',
  CHANNEL_VIDEO_LOADED = 'CHANNEL_VIDEO_LOADED',
  CHANNEL_VIDEO_UNLOADED = 'CHANNEL_VIDEO_UNLOADED',
  FILTER_BY_TAG = 'FILTER_BY_TAG',
  FILTER_BY_SEARCH = 'FILTER_BY_SEARCH',
  VIDEO_PLAYER_MOUNTED = 'VIDEO_PLAYER_MOUNTED',
  VIDEO_PLAYER_RENDERED = 'VIDEO_PLAYER_RENDERED',
  CHANNEL_EMBED_MOUNTED = 'CHANNEL_EMBED_MOUNTED',
  CHANNEL_EMBED_RENDERED = 'CHANNEL_EMBED_RENDERED',
}
