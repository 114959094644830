// See https://stackoverflow.com/a/3540295/7617726
// for a more thorough (but ugly) approach to determining a mobile device:
export const isMobile = () =>
  typeof navigator !== 'undefined' && /Mobi|Android/i.test(navigator.userAgent)

export function isTouchDevice() {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch (e) {
    return false
  }
}
