import Vue from 'vue';
import '@lumiere/shared/services/sentry';
import '@/services/initLogger';
import '@/services/firebase/performance';
import 'videojs-mux';
import createApp from './viewer.createApp';
import logger from '@lumiere/shared/services/logger';
logger.log('TAG_NAME', process.env.VUE_APP_TAG_NAME);
logger.log('COMMIT_SHA', process.env.VUE_APP_COMMIT_SHA);
Vue.config.productionTip = false;
const { app, router, store } = createApp();
router.onReady(() => {
    if (window.__INITIAL_STATE__) {
        const geo_data = window.__GEO_DATA__ ?? window.__INITIAL_STATE__.geo_data;
        store.replaceState({
            ...window.__INITIAL_STATE__,
            geo_data,
        });
    }
    app.$mount('#app');
}, (err) => {
    console.error(err);
});
