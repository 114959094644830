import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './store.mutations';
import actions from './store.actions';
import getters from './store.getters';
import defaultState from './store.state';
Vue.use(Vuex);
export default function createStore() {
    return new Vuex.Store({
        state: defaultState(),
        getters,
        mutations,
        actions,
        modules: {},
    });
}
