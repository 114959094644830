var MUTATION;
(function (MUTATION) {
    MUTATION["SET_VIDEO"] = "SET_VIDEO";
    MUTATION["SET_USER"] = "SET_USER";
    MUTATION["SET_QUERY_PARAMS"] = "SET_QUERY_PARAMS";
    MUTATION["SET_IFRAME_EMBED_DATA"] = "SET_IFRAME_EMBED_DATA";
    MUTATION["SET_CHANNEL"] = "SET_CHANNEL";
    MUTATION["SET_VIEW_PROMISE"] = "SET_VIEW_PROMISE";
    MUTATION["UPDATE_USER_ENVIRONMENT"] = "UPDATE_USER_ENVIRONMENT";
})(MUTATION || (MUTATION = {}));
export default MUTATION;
