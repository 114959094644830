import Vue from 'vue';
import createStore from './store/store';
import ViewerApp from './ViewerApp.vue';
import createRouter from './router';
export default function createApp() {
    const store = createStore();
    const router = createRouter();
    const app = new Vue({
        store,
        router,
        render: (h) => h(ViewerApp),
    });
    return {
        app,
        router,
        store,
    };
}
