const defaultState = () => ({
    video: null,
    playlist: null,
    channel: null,
    user: null,
    viewPromise: null,
    queryParams: {},
    iFrameEmbed: null,
    userEnvironment: null,
    geo_data: {
        country_iso_code: null,
        region_iso_code: null,
        location: null,
    },
});
export default defaultState;
