import Vue from 'vue';
import VueRouter from 'vue-router';
import ViewerDefault from './views/ViewerDefault.vue';
import SimulateError from './views/SimulateError.vue';
Vue.use(VueRouter);
export var ViewerRoutes;
(function (ViewerRoutes) {
    ViewerRoutes["Default"] = "Default";
    ViewerRoutes["Video"] = "Video";
    ViewerRoutes["Playlist"] = "Playlist";
    ViewerRoutes["Channel"] = "Channel";
    ViewerRoutes["ChannelVideos"] = "ChannelVideos";
    ViewerRoutes["ChannelVideo"] = "ChannelVideo";
})(ViewerRoutes || (ViewerRoutes = {}));
export default function createRouter() {
    return new VueRouter({
        mode: 'history',
        routes: [
            {
                name: ViewerRoutes.Default,
                path: '/',
                component: ViewerDefault,
            },
            {
                name: 'SimulateError',
                path: '/simulate-error',
                component: SimulateError,
            },
            {
                name: ViewerRoutes.Video,
                path: '/v/:vid',
                component: async () => import(
                /* webpackChunkName: "viewer-video" */ './views/ViewerVideo.vue'),
                props: true,
            },
            {
                name: ViewerRoutes.Playlist,
                path: '/p/:pid',
                component: async () => import(
                /* webpackChunkName: "viewer-playlist" */ './views/ViewerPlaylist.vue'),
                props: true,
            },
            { path: '/c/:cid', redirect: '/c/:cid/videos' },
            {
                name: ViewerRoutes.Channel,
                path: '/c/:cid',
                component: async () => import(
                /* webpackChunkName: "viewer-channel" */ './views/ViewerChannel.vue'),
                props: true,
                children: [
                    {
                        name: ViewerRoutes.ChannelVideos,
                        path: 'videos',
                        component: async () => import(
                        /* webpackChunkName: "viewer-channel-videos" */ './views/ViewerChannelVideos.vue'),
                        props: true,
                    },
                    {
                        name: ViewerRoutes.ChannelVideo,
                        path: 'videos/:vid',
                        props: true,
                        component: async () => import(
                        /* webpackChunkName: "viewer-channel-video" */ './views/ViewerChannelVideo.vue'),
                    },
                ],
            },
        ],
    });
}
