export type TextSpan = {
  content?: string | null
  beginOffset?: number | null
}

export type SentimentSentences = {
  text?: TextSpan | null
  sentiment?: Sentiment | null
}

export type Sentiment = {
  magnitude?: number | null
  score?: number | null
}

export enum TypeEnums {
  UNKNOWN = 'UNKNOWN',
  PERSON = 'PERSON',
  LOCATION = 'LOCATION',
  ORGANIZATION = 'ORGANIZATION',
  EVENT = 'EVENT',
  WORK_OF_ART = 'WORK_OF_ART',
  CONSUMER_GOOD = 'CONSUMER_GOOD',
  OTHER = 'OTHER',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ADDRESS = 'ADDRESS',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  PRICE = 'PRICE',
}

export type EntityMentions = {
  text?: TextSpan | null
  type?: 'TYPE_UNKNOWN' | 'PROPER' | 'COMMON' | any
  sentiment?: Sentiment | null
}
export interface Entity {
  name?: string | null
  type?: TypeEnums | any
  metadata?: {
    [key: string]: string
  } | null
  salience?: number | null
  mentions?: EntityMentions[] | null
  sentiment?: Sentiment | null
}

export interface NLDocument {
  content: string
  language?: string
  type: 'PLAIN_TEXT' | 'HTML'
}

export interface GenericType {
  [key: string]: any
}

// sentiment analysis type
export interface SentimentAnalysis {
  language?: string | null
  documentSentiment?: Sentiment | null
  sentences?: SentimentSentences[] | null
}

// entity analysis type
export type EntityAnalysis = Entity[]

// entity sentiment analysis type
export type EntitySentimentAnalysis = EntitySentiment[]

export interface EntitySentiment extends Entity {}

export type EncodingType =
  | 'UTF8'
  | 'NONE'
  | 'UTF16'
  | 'UTF32'
  | null
  | undefined
