import Vue from 'vue';
import MUTATION from '@/store/MUTATION';
import { isTouchDevice } from '@lumiere/shared/utils/helpers';
export default Vue.extend({
    name: 'CaptureScreenOritentation',
    beforeMount() {
        this.handleScreenOrientation();
    },
    mounted() {
        window.addEventListener('resize', this.handleScreenOrientation);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleScreenOrientation);
    },
    methods: {
        handleScreenOrientation() {
            // get orientation using screen.orientation or window.orientation
            let orientation = (screen.orientation?.angle ?? window.orientation) == 0
                ? 'portrait'
                : 'landscape';
            // favor window.matchMedia prop if it matches/exists
            if (window.matchMedia('(orientation: portrait)').matches) {
                orientation = 'portrait';
            }
            else if (window.matchMedia('(orientation: landscape)').matches) {
                orientation = 'landscape';
            }
            const payload = {
                viewport: {
                    width: window.innerWidth,
                    height: window.innerHeight,
                },
                orientation,
                isTouchDevice: isTouchDevice(),
            };
            this.$store.commit(MUTATION.UPDATE_USER_ENVIRONMENT, payload);
        },
    },
});
