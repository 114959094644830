var ACTION;
(function (ACTION) {
    ACTION["FETCH_VIDEO"] = "FETCH_VIDEO";
    ACTION["POST_DATA"] = "POST_DATA";
    ACTION["SUBSCRIBE_TO_AUTH_STATE_CHANGE"] = "SUBSCRIBE_TO_AUTH_STATE_CHANGE";
    ACTION["CREATE_VIEW_RECORD"] = "CREATE_VIEW_RECORD";
    ACTION["AUTHENTICATE"] = "AUTHENTICATE";
    ACTION["POST_EMOJI_DATA"] = "POST_EMOJI_DATA";
    ACTION["GET_TOTAL_EMOJI_CLICKS"] = "GET_TOTAL_EMOJI_CLICKS";
    ACTION["GET_FLY_OUT_EMOJIS"] = "GET_FLY_OUT_EMOJIS";
    ACTION["VIEW_SESSION_ENDED"] = "VIEW_SESSION_ENDED";
    ACTION["TRACK_VIDEO_ENGAGEMENT"] = "TRACK_VIDEO_ENGAGEMENT";
    ACTION["NOTIFY_PARENT_WINDOW"] = "NOTIFY_PARENT_WINDOW";
    ACTION["FETCH_CHANNEL"] = "FETCH_CHANNEL";
})(ACTION || (ACTION = {}));
export default ACTION;
