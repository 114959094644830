import MUTATION from './MUTATION';
import locallyStored from '@lumiere/shared/utils/locallyStored';
const locallyStoredEID = locallyStored('EID');
const mutations = {
    [MUTATION.SET_VIDEO](state, { video, features }) {
        state.video = { video, features };
    },
    [MUTATION.SET_USER](state, user) {
        const userId = user ? user.uid : null;
        if (!state.user || state.user.uid !== userId) {
            state.user = user;
        }
    },
    [MUTATION.SET_VIEW_PROMISE](state, viewPromise) {
        state.viewPromise = viewPromise;
    },
    [MUTATION.SET_QUERY_PARAMS](state, queryParams) {
        let eid = queryParams.eid;
        if (typeof window !== 'undefined') {
            if (eid) {
                locallyStoredEID.set(eid);
            }
            else {
                eid = locallyStoredEID.get();
            }
        }
        const { t: timeString } = queryParams;
        let seconds = {};
        if (timeString) {
            const minAndSec = timeString.match(/^([0-9]+)m([0-9]+)s$/);
            const minOnly = timeString.match(/^([0-9]+)m$/);
            const secOnly = timeString.match(/^([0-9]+)s$/);
            let min = 0;
            let sec = 0;
            if (minAndSec) {
                min = parseInt(minAndSec[1]);
                sec = parseInt(minAndSec[2]);
            }
            else if (minOnly) {
                min = parseInt(minOnly[1]);
            }
            else if (secOnly) {
                sec = parseInt(secOnly[1]);
            }
            seconds.time = min * 60 + sec;
        }
        state.queryParams = { ...queryParams, eid, ...seconds };
    },
    [MUTATION.SET_IFRAME_EMBED_DATA](state, data) {
        state.iFrameEmbed = data;
    },
    [MUTATION.SET_CHANNEL](state, data) {
        state.channel = data;
    },
    [MUTATION.UPDATE_USER_ENVIRONMENT](state, data) {
        state.userEnvironment = data;
    },
};
export default mutations;
