import firebase from './firebase';
import 'firebase/auth';
const auth = firebase.auth();
export default auth;
export const getToken = async () => new Promise((resolve, reject) => {
    if (auth.currentUser) {
        resolve(auth.currentUser.getIdToken());
    }
    else {
        const unsubscribe = auth.onIdTokenChanged((user) => {
            if (user) {
                user.getIdToken().then(resolve);
                unsubscribe();
            }
        }, (error) => reject(error));
    }
});
